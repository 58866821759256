import { useMemo } from "react";
import { TouchableProps } from "../types";

const joinClassNames = (classNames: (string | undefined)[]) => {
	return classNames.filter(Boolean).join(" ");
};
const useTouchableClassName = ({
	cssClassName,
	disabled,
	animation,
	hasDisabledStyling,
}: {
	cssClassName?: string;
	disabled: boolean | undefined;
	animation: NonNullable<TouchableProps["animation"]>;
	hasDisabledStyling: boolean;
}) => {
	const className = useMemo(
		() =>
			joinClassNames([
				cssClassName,
				"touchable",
				disabled
					? hasDisabledStyling
						? "touchable-disabled"
						: "touchable-disabled-no-style"
					: "touchable-enabled",
				animation !== "None" && !disabled ? `touchable-animated` : undefined,
			]),
		[animation, cssClassName, disabled, hasDisabledStyling],
	);
	return className;
};
export default useTouchableClassName;
