import { View } from "react-native";
import useStyle from "src/hooks/useStyle";
import { IconComponent } from "src/icons";
import { color } from "src/styles";
import { paddingLarge, paddingMedium } from "src/styles/spacing";
import { Link } from "src/swsh-native";

const FooterIconButton = ({
	Icon,
	url,
	alt,
}: {
	Icon: IconComponent;
	url: string;
	alt: string;
}) => {
	const { theme } = useStyle();

	return (
		<View
			style={{
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Link
				href={url}
				target="_blank"
				alt={alt}
				style={{
					paddingHorizontal: paddingLarge,
					paddingVertical: paddingMedium,
				}}
			>
				<Icon size={20} color={color.TextLight[theme]} />
			</Link>
		</View>
	);
};
export default FooterIconButton;
