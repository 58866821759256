import useParseViewPropsToWeb from "src/hooks/useParseViewPropsToWeb";
import useTouchableClassName from "../hooks/useTouchableClassName";
import { _InnerTouchableProps, TouchableProps } from "../types";

const WebInnerTouchablePress = ({
	containerStyle,
	disabled,
	onPress,
	testID,
	children,
	animation,
	hasDisabledStyling,
}: _InnerTouchableProps & {
	animation: NonNullable<TouchableProps["animation"]>;
	hasDisabledStyling: boolean;
}) => {
	let { style: cssStyle, className: cssClassName } = useParseViewPropsToWeb({
		style: containerStyle,
	});
	const className = useTouchableClassName({
		cssClassName,
		disabled,
		animation,
		hasDisabledStyling,
	});

	return (
		<div
			className={className}
			data-testid={testID}
			onClick={onPress}
			role="button"
			style={cssStyle}
		>
			{children}
		</div>
	);
};
export default WebInnerTouchablePress;
