import "./css/touchable.css";

import { View } from "react-native";
import WebInnerTouchableLink from "./components/WebInnerTouchableLink.web";
import WebInnerTouchablePress from "./components/WebInnerTouchablePress.web";
import { TouchableProps } from "./types";

const Touchable = ({
	children,
	style,
	animation = "Auto",
	disabled,
	onLayout,
	hasDisabledStyling = true,
	...rest
}: TouchableProps) => {
	if (rest.href) {
		return (
			<WebInnerTouchableLink
				disabled={disabled}
				animation={animation}
				hasDisabledStyling={hasDisabledStyling}
				{...rest}
			>
				<View style={style} onLayout={onLayout}>
					{children}
				</View>
			</WebInnerTouchableLink>
		);
	}
	return (
		<WebInnerTouchablePress
			disabled={disabled}
			animation={animation}
			hasDisabledStyling={hasDisabledStyling}
			{...rest}
		>
			<View style={style} onLayout={onLayout}>
				{children}
			</View>
		</WebInnerTouchablePress>
	);
};
export default Touchable;
