import NextImage from "next/image";
import type { ReactEventHandler } from "react";
import { memo, useCallback, useMemo, useState } from "react";
import { View } from "react-native";
import log from "src/helpers/log";
import useParseImageDim from "./hooks/useParseImageDim";
import useParseSizes from "./hooks/useParseSizes";
import { ImageProps } from "./types";

const Image = ({
	alt,
	src,
	dim,
	contentFit = "cover",
	contentPosition,
	style: style,
	recyclingKey,
	priority = "normal",
	pointerEvents,
	sizes,
	onLoad,
	onError,
	testID,
	unoptimized: unoptimizedProp,
	enteringAnimation = true,
	webLoadingBehavior,
	blurRadius,
}: ImageProps) => {
	const { width, height, fill } = useParseImageDim(dim);
	const parsedSizes = useParseSizes(sizes);
	const [didLoad, setDidLoad] = useState(false);

	const handleLoad = useCallback<ReactEventHandler<HTMLImageElement>>(
		(e) => {
			setDidLoad(true);
			onLoad?.({
				cacheType: "memory",
				height: e.currentTarget.naturalHeight,
				width: e.currentTarget.naturalWidth,
				url: typeof src === "string" ? src : null,
			});
		},
		[onLoad, src],
	);
	const handleError = useCallback<ReactEventHandler<HTMLImageElement>>(() => {
		onError?.({
			error: "Image failed to load",
		});
		// Not erroring if image fails to load since it's most likely network issues
		// You should likely look at CloudFront metrics to see error rates
		log.warn("Image failed to load");
	}, [onError]);
	const unoptimized = useMemo(() => {
		return unoptimizedProp || (typeof src === "string" && src.includes(".cloudfront.net"));
	}, [src, unoptimizedProp]);

	return (
		<View
			testID={testID}
			style={[{ width, height, overflow: "hidden" }, style]}
			pointerEvents={pointerEvents}
		>
			<NextImage
				key={recyclingKey}
				loading={webLoadingBehavior}
				fill={fill}
				width={width}
				height={height}
				alt={alt}
				src={src}
				style={{
					objectFit: contentFit,
					objectPosition: contentPosition,
					filter: blurRadius ? `blur(${blurRadius}px)` : undefined,
					opacity: !enteringAnimation ? 1 : didLoad ? 1 : 0,
					transition: enteringAnimation ? "opacity 0.2s ease-in-out" : undefined,
				}}
				priority={priority === "high"}
				sizes={parsedSizes}
				onLoad={handleLoad}
				onError={handleError}
				unoptimized={unoptimized}
			/>
		</View>
	);
};
export default memo(Image);
