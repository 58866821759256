import { View } from "react-native";
import useDevice from "src/hooks/useDevice";
import { textSpaceBetween } from "src/screens/(informational)/InformationalHome/styles";
import { paddingMedium, paddingXXXL } from "src/styles/spacing";
import { Text } from "src/swsh-native";
import DownloadBadge from "../../DownloadBadge";
import TextSectionContainer from "../../TextSectionContainer";

const translateDistance = 50;
const TextSection = ({
	titleIntro,
	titleVerb,
	body,
}: {
	titleIntro: string;
	titleVerb: string;
	body: string;
}) => {
	const { device } = useDevice();

	return (
		<TextSectionContainer style={{ alignItems: "flex-start", width: "auto" }}>
			<Text
				h2
				style={{
					textAlign: "left",
				}}
				aria-level={2}
			>
				{titleIntro}
			</Text>
			<Text
				h2
				bold
				style={{
					textAlign: "left",
				}}
				accessibilityRole="heading"
				aria-level={2}
			>
				{titleVerb}
			</Text>
			<Text
				h4
				color="TextLight"
				style={{
					marginTop: textSpaceBetween[device],
					textAlign: device === "mobile" ? "center" : "left",
				}}
				accessibilityRole="heading"
				aria-level={2}
			>
				{body}
			</Text>
			<View
				style={{
					marginTop: paddingXXXL,
					flexDirection: "row",
					columnGap: paddingMedium,
					rowGap: paddingMedium,
					alignItems: "center",
					flexWrap: "wrap",
					justifyContent: "center",
				}}
			>
				<DownloadBadge variant="AppStore" />
				<DownloadBadge variant="GooglePlay" />
			</View>
		</TextSectionContainer>
	);
};
export default TextSection;
