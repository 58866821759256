import Link from "next/link";
import { useCallback } from "react";
import useParseViewPropsToWeb from "src/hooks/useParseViewPropsToWeb";
import amplitudeLinkPress from "../helpers/amplitudeLinkPress";
import useTouchableClassName from "../hooks/useTouchableClassName";
import { _InnerTouchableProps, TouchableProps } from "../types";

const WebInnerTouchableLink = ({
	containerStyle,
	disabled,
	href = "/",
	onPress,
	testID,
	children,
	target,
	linkDraggable,
	animation,
	hasDisabledStyling,
}: _InnerTouchableProps & {
	animation: NonNullable<TouchableProps["animation"]>;
	hasDisabledStyling: boolean;
}) => {
	let { style: cssStyle, className: cssClassName } = useParseViewPropsToWeb({
		style: containerStyle,
	});
	const className = useTouchableClassName({
		cssClassName,
		disabled,
		animation,
		hasDisabledStyling,
	});
	const handlePress = useCallback(async () => {
		onPress?.();
		amplitudeLinkPress(href);
	}, [onPress, href]);

	return (
		<Link
			className={className}
			data-testid={testID}
			onClick={handlePress}
			role="link"
			style={cssStyle}
			href={href}
			target={target}
			draggable={linkDraggable}
		>
			{children}
		</Link>
	);
};
export default WebInnerTouchableLink;
