import { track } from "@amplitude/analytics-react-native";
import NextLink from "next/link";
import { amp } from "src/api/amplitude";
import extractDomainName from "src/helpers/extractDomainName";
import useParseViewPropsToWeb from "src/hooks/useParseViewPropsToWeb";
import HiddenAltText from "../HiddenAltText";
import { NativeLinkProps } from "./types";

const NativeLink = ({
	children,
	href,
	target,
	style,
	testID,
	amplitude,
	draggable,
	replace,
	onPress,
	webDisplayOverride,
	alt,
}: NativeLinkProps) => {
	let { style: cssStyle, className } = useParseViewPropsToWeb({
		style,
	});

	if (webDisplayOverride) {
		cssStyle = { ...cssStyle, display: webDisplayOverride };
	}

	return (
		<NextLink
			className={className}
			href={href}
			target={target}
			draggable={draggable}
			replace={replace}
			style={cssStyle}
			onClick={() => {
				onPress?.();
				if (amplitude) {
					track(amplitude.eventName, amplitude.properties);
				} else {
					track(amp.link.click, {
						href,
						domainName: extractDomainName(href),
					});
				}
			}}
			data-testid={testID}
		>
			{children}
			<HiddenAltText>{alt}</HiddenAltText>
		</NextLink>
	);
};
export default NativeLink;
