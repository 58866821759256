import type { Variants } from "framer-motion";
import { CSSProperties, useMemo, useState } from "react";
import { useWindowDimensions } from "react-native";
import getOptimizedVideoUrl from "src/assets/__generated__/videos/marketing_home/getOptimizedVideoUrl";
import useDevice from "src/hooks/useDevice";
import useNavBarSpacing from "src/screens/(informational)/hooks/useNavBarSpacing";
import { paddingLarge } from "src/styles/spacing";
import { MDiv } from "src/swsh-native";

const ContainerVariantsDesktop = {
	visible: {
		transform: "translateX(0px)",
		opacity: 1,
	},
	default: {
		transform: "translateX(100px)",
		opacity: 0.5,
	},
} satisfies Variants;
const ContainerVariantsMobile = {
	visible: {
		transform: "translateX(0px)",
		opacity: 1,
	},
	default: {
		transform: "translateX(0px)",
		opacity: 0,
	},
} satisfies Variants;

const VideoSection = ({ style }: { style?: CSSProperties }) => {
	const { device } = useDevice();
	const dim = useWindowDimensions();
	const { paddingRight } = useNavBarSpacing();
	const { webmVideoUrl, mp4VideoUrl, movVideoUrl } = useMemo(() => {
		return {
			webmVideoUrl: getOptimizedVideoUrl({ size: device, format: "webm" }),
			mp4VideoUrl: getOptimizedVideoUrl({ size: device, format: "mp4" }),
			movVideoUrl: getOptimizedVideoUrl({ size: device, format: "mov" }),
		};
	}, [device]);
	const { width, aspectRatio, maxHeight } =
		device === "mobile"
			? {
					width: Math.floor(dim.width - 2 * paddingLarge),
					aspectRatio: 469 / 390,
					maxHeight: Math.floor(1 * dim.height),
				}
			: {
					width: Math.floor(0.5 * dim.width),
					aspectRatio: 469 / 390,
					maxHeight: Math.floor(0.7 * dim.height),
				};
	const height = Math.floor(Math.min(maxHeight, width * aspectRatio));
	const [isActive, setIsActive] = useState(false);

	return (
		<MDiv
			initial="default"
			animate={isActive ? "visible" : "default"}
			viewport={{ once: true }}
			variants={device === "mobile" ? ContainerVariantsMobile : ContainerVariantsDesktop}
			transition={
				device === "mobile"
					? { duration: 0.15 }
					: {
							duration: 0.4,
						}
			}
			style={[
				{
					justifyContent: "center",
				},
				device === "mobile"
					? {
							alignItems: "center",
							marginTop: paddingLarge,
						}
					: {
							alignItems: "flex-start",
							marginRight: paddingRight,
						},
				style,
			]}
		>
			<video
				width={width}
				height={height}
				autoPlay
				muted
				loop
				playsInline
				onLoadedData={() => {
					setIsActive(true);
				}}
			>
				<source src={webmVideoUrl} type="video/webm" />
				<source src={movVideoUrl} type="video/quicktime" />
				<source src={mp4VideoUrl} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
		</MDiv>
	);
};
export default VideoSection;
