import { CSSProperties, useMemo } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { UseParseViewPropsToWeb } from "./types";

type PointerEvent = "box-none" | "box-only" | "none" | "auto";
const parsePointerEvents = (pointerEvents: PointerEvent) => {
	switch (pointerEvents) {
		case "box-none":
			return "pointerEvents-boxNone";
		case "box-only":
			return "pointerEvents-boxOnly";
		case "none":
			return "pointerEvents-none";
		case "auto":
			return "pointerEvents-auto";
		default:
			return "";
	}
};

const useParseViewPropsToWeb: UseParseViewPropsToWeb = ({
	style: styleProp,
	pointerEvents: pointerEventsProp = "auto",
}) => {
	const { style, className } = useMemo<{
		style: CSSProperties;
		className: string;
	}>(() => {
		const flattenedStyleProp = StyleSheet.flatten(styleProp) as ViewStyle;

		const retStyle = StyleSheet.flatten([
			// React Native default styles. If you ever want to find these, just create a basic view and find the CSS
			{
				position: "relative",
				display: "flex",
				flexDirection: "column",
				alignItems: "stretch",
				border: "0 solid black",
				borderTopColor: "black",
				borderTopWidth: 0,
				boxSizing: "border-box",
				flexBasis: "auto",
				flexShrink: 0,
				listStyle: "none",
				margin: 0,
				minHeight: 0,
				minWidth: 0,
				padding: 0,
				textDecoration: "none",
				zIndex: 0,
			},
			{
				borderStyle:
					flattenedStyleProp?.borderStyle ??
					(flattenedStyleProp?.borderWidth ||
					flattenedStyleProp.borderTopWidth ||
					flattenedStyleProp.borderBottomWidth ||
					flattenedStyleProp.borderLeftWidth ||
					flattenedStyleProp.borderRightWidth
						? "solid"
						: undefined),
				paddingLeft:
					flattenedStyleProp?.paddingLeft ??
					flattenedStyleProp?.paddingHorizontal ??
					flattenedStyleProp?.padding,
				paddingRight:
					flattenedStyleProp?.paddingRight ??
					flattenedStyleProp?.paddingHorizontal ??
					flattenedStyleProp?.padding,
				paddingTop:
					flattenedStyleProp?.paddingTop ??
					flattenedStyleProp?.paddingVertical ??
					flattenedStyleProp?.padding,
				paddingBottom:
					flattenedStyleProp?.paddingBottom ??
					flattenedStyleProp?.paddingVertical ??
					flattenedStyleProp?.padding,
				backgroundColor: flattenedStyleProp?.backgroundColor as string | undefined,
				marginLeft:
					flattenedStyleProp?.marginLeft ??
					flattenedStyleProp?.marginHorizontal ??
					flattenedStyleProp?.margin,
				marginRight:
					flattenedStyleProp?.marginRight ??
					flattenedStyleProp?.marginHorizontal ??
					flattenedStyleProp?.margin,
				marginTop:
					flattenedStyleProp?.marginTop ??
					flattenedStyleProp?.marginVertical ??
					flattenedStyleProp?.margin,
				marginBottom:
					flattenedStyleProp?.marginBottom ??
					flattenedStyleProp?.marginVertical ??
					flattenedStyleProp?.margin,
			},
			styleProp,
		]);

		return {
			style: retStyle as CSSProperties,
			className: parsePointerEvents(pointerEventsProp),
		};
	}, [pointerEventsProp, styleProp]);

	return { style, className };
};
export default useParseViewPropsToWeb;
