import { StyleProp, View, ViewStyle } from "react-native";
import useDevice from "src/hooks/useDevice";
import { paddingXXXL } from "src/styles/spacing";
import TextSection from "./components/TextSection";
import VideoSection from "./components/VideoSection.web";

const IntroSection = ({
	titleIntro,
	titleVerb,
	body,
	style,
}: {
	titleIntro: string;
	titleVerb: string;
	body: string;
	style?: StyleProp<ViewStyle>;
}) => {
	const { device } = useDevice();

	return (
		<View
			style={[
				{
					width: "100%",
					flex: 1,
					overflow: "hidden",
				},
				device === "mobile"
					? {
							flexDirection: "column",
							alignItems: "center",
							marginTop: paddingXXXL,
						}
					: {
							flexDirection: "row",
							justifyContent: "space-between",
						},
				style,
			]}
		>
			<TextSection titleIntro={titleIntro} titleVerb={titleVerb} body={body} />
			<VideoSection />
		</View>
	);
};
export default IntroSection;
