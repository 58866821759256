import { track } from "@amplitude/analytics-react-native";
import { amp } from "src/api/amplitude";
import extractDomainName from "src/helpers/extractDomainName";

const amplitudeLinkPress = (href: string) => {
	track(amp.link.click, {
		href,
		domainName: extractDomainName(href),
	});
};
export default amplitudeLinkPress;
