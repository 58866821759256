import { AnimatedMenuRoute } from "src/components/AnimatedMenu/types";

export const InformationalNavRoutes: AnimatedMenuRoute[] = [
	{
		title: "FAQ",
		href: "/faq",
	},
	{
		title: "Our Story",
		href: "/our-story",
	},
	{
		title: "Careers",
		href: "/careers",
	},
	{
		title: "Updates",
		href: "/updates",
	},
	{
		title: "Terms",
		href: "/terms",
	},
	{
		title: "Privacy",
		href: "/policy",
	},
];
