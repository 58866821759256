"use client";
import IntroSection from "../components/IntroSection/IntroSection.web";

const InformationalHome = () => {
	return (
		<IntroSection
			titleIntro="swsh is the easiest way to"
			titleVerb="share photo albums"
			body="Never miss your photos"
		/>
	);
};
export default InformationalHome;
