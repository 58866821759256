"use client";

import { MDiv } from "src/swsh-native";
import InformationalFooter from "./components/InformationalFooter/InformationalFooter.web";
import InformationalHeader from "./components/InformationalHeader/InformationalHeader.web";
import { InformationalContentWrapperProps } from "./types";

const InformationalContentWrapper = ({ children }: InformationalContentWrapperProps) => {
	return (
		<MDiv
			initial={{
				// We want to start it somewhat visible for SEO LCP to be happy
				opacity: 0.001,
			}}
			animate={{
				opacity: 1,
			}}
			transition={{
				duration: 0.1,
				type: "tween",
			}}
			style={{
				height: "100%",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<InformationalHeader />
			<MDiv
				style={{
					flex: 1,
				}}
			>
				{children}
			</MDiv>
			<InformationalFooter />
		</MDiv>
	);
};
export default InformationalContentWrapper;
