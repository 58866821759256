"use client";

import { View } from "react-native";
import IconInstagram from "src/icons/IconInstagram";
import IconLinkedIn from "src/icons/IconLinkedIn";
import IconTikTok from "src/icons/IconTikTok";
import IconTwitter from "src/icons/IconTwitter";
import { InformationalNavRoutes } from "src/screens/(informational)/constants";
import { paddingMedium, paddingSmall, paddingXL } from "src/styles/spacing";
import { Text } from "src/swsh-native";
import FooterIconButton from "./components/FooterIconButton";
import NavBarItem from "./components/NavBarItem.web";

const InformationalFooter = () => {
	return (
		<View
			testID="FooterContainer"
			style={[
				{
					width: "100%",
					flexDirection: "row",
					paddingTop: paddingMedium,
					marginBottom: paddingXL,
					alignItems: "flex-start",
					justifyContent: "space-between",
					paddingHorizontal: "4%",
					columnGap: paddingSmall,
				},
			]}
		>
			<View
				style={{
					flex: 2,
					flexDirection: "row",
					flexWrap: "wrap",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				{InformationalNavRoutes.map((route) => (
					<NavBarItem href={route.href} key={route.href} target={route.target}>
						<Text
							style={{
								textTransform: "uppercase",
							}}
						>
							{route.title}
						</Text>
					</NavBarItem>
				))}
			</View>
			<View
				style={{
					flex: 1,
					maxWidth: 300,
					flexDirection: "row",
					flexWrap: "wrap",
					justifyContent: "flex-end",
					alignItems: "center",
				}}
			>
				<FooterIconButton
					Icon={IconTikTok}
					url="https://www.tiktok.com/@swsh.sorority"
					alt="TikTok"
				/>
				<FooterIconButton
					Icon={IconInstagram}
					url="https://www.instagram.com/joinswsh/"
					alt="Instagram"
				/>
				<FooterIconButton
					Icon={IconLinkedIn}
					url="https://www.linkedin.com/company/joinswsh/"
					alt="LinkedIn"
				/>
				<FooterIconButton
					Icon={IconTwitter}
					url="https://twitter.com/joinswsh"
					alt="Twitter"
				/>
			</View>
		</View>
	);
};
export default InformationalFooter;
