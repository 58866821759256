import type { Variants } from "framer-motion";
import { ReactNode } from "react";
import { Link } from "solito/link";
import useStyle from "src/hooks/useStyle";
import { color } from "src/styles";
import { paddingLarge, paddingMedium } from "src/styles/spacing";
import { MDiv } from "src/swsh-native";

const variants = {
	active: {
		transform: "scaleY(0.98)",
		opacity: 0.6,
	},
	default: {
		transform: "scaleY(0.8)",
		opacity: 0,
	},
} satisfies Variants;

const NavBarItem = ({
	href,
	children,
	target,
}: {
	children?: ReactNode;
	href: string;
	target?: "_blank" | "_self" | "_parent" | "_top";
}) => {
	const { theme } = useStyle();

	return (
		<Link href={href} target={target}>
			<MDiv
				initial="default"
				whileHover="active"
				className="nav-item"
				style={[
					{
						paddingHorizontal: paddingLarge,
						paddingVertical: paddingMedium,
						alignItems: "center",
						justifyContent: "center",
						borderColor: color.Divider[theme],
					},
				]}
			>
				{children}
				<MDiv
					variants={variants}
					style={{
						position: "absolute",
						width: "80%",
						height: "100%",
						zIndex: 1,
						alignSelf: "center",
						borderTopStyle: "solid",
						borderBottomStyle: "solid",
						borderTopWidth: 1,
						borderBottomWidth: 1,
						borderColor: color.TextContrast[theme],
					}}
				/>
			</MDiv>
		</Link>
	);
};
export default NavBarItem;
