"use client";
import { StyleProp, View, ViewStyle } from "react-native";
import Touchable from "src/components/Touchable";
import { APP_STORE_URL } from "src/constants/appStore";
import cursor from "src/constants/cursor";
import { GOOGLE_PLAY_STORE_URL } from "src/constants/googlePlayStore";
import useDevice from "src/hooks/useDevice";
import IconAppleAppStoreBadge from "src/icons/IconAppleAppStoreBadge";
import IconGooglePlayBadge from "src/icons/IconGooglePlayBadge";
import { useModal } from "src/modals";
import InformationalModal from "src/modals/configs/InformationalModal";
import HiddenAltText from "src/swsh-native/Link/components/HiddenAltText";

const SourceLookup = {
	GooglePlay: {
		Icon: IconGooglePlayBadge,
		aspectRatio: 180 / 53,
		url: GOOGLE_PLAY_STORE_URL,
		alt: "Download on Google Play",
		testID: "DownloadOnGooglePlay",
	},
	AppStore: {
		Icon: IconAppleAppStoreBadge,
		aspectRatio: 120 / 40,
		url: APP_STORE_URL,
		alt: "Download on App Store",
		testID: "DownloadOnAppStore",
	},
};

const DownloadBadge = ({
	style,
	variant,
}: {
	style?: StyleProp<ViewStyle>;
	variant: "GooglePlay" | "AppStore";
}) => {
	const { device } = useDevice();
	const height = device === "mobile" ? 40 : 52;
	const { presentModal } = useModal<InformationalModal>();
	const { Icon, aspectRatio, url, alt, testID } = SourceLookup[variant];

	const Content = <Icon size={height - 1} />;

	return (
		<View
			testID={testID}
			style={[
				{
					height,
					aspectRatio,
					cursor: cursor.pointer,
					justifyContent: "center",
					alignItems: "center",
				},
				style,
			]}
		>
			{device === "mobile" ? (
				<Touchable href={url} target="_blank">
					{Content}
					<HiddenAltText>{alt}</HiddenAltText>
				</Touchable>
			) : (
				<Touchable
					onPress={() => {
						presentModal("ScanToDownload", {
							props: {
								url,
							},
						});
					}}
				>
					{Content}
					<HiddenAltText>{alt}</HiddenAltText>
				</Touchable>
			)}
		</View>
	);
};
export default DownloadBadge;
